import { FC } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Formik } from 'formik';
import axios from "axios";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Riydah, Saudi Arabia",
  },
  {
    title: "💌 EMAIL",
    desc: "info@enjooyapp.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];

interface MyFormValues {
  email: string;
  name: string;
  message: string;
  phone: string;
}

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const initialValues: MyFormValues = { name: '', email: '', message: '', phone: '' };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || Enjooy App</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, {resetForm, setSubmitting}) => {
            try {
              setSubmitting(true);
              const response = await axios?.post(`https://api.enjooyapp.com/api/v1/contacts`, {
                name: values?.name,
                mobile: values?.phone,
                message: values?.message,
                email: values?.email
              })
              if(response?.data?.status === 'success') {
                setSubmitting(false);
                resetForm();
                alert('Message sent to Enjooy App, Thank you,');
              }
            } catch (error: any) {
              setSubmitting(false);
            }
          }}
        >
          {({handleChange, values, handleBlur, handleSubmit, isSubmitting}) => (
            <div className="container max-w-7xl mx-auto">
              <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
                <div className="max-w-sm space-y-8">
                  {info.map((item, index) => (
                    <div key={index}>
                      <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                        {item.title}
                      </h3>
                      <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                        {item.desc}
                      </span>
                    </div>
                  ))}
                  <div>
                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                      🌏 SOCIALS
                    </h3>
                    <SocialsList className="mt-2" />
                  </div>
                </div>
                <div>
                  <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                    <label className="block">
                      <Label>Full name</Label>

                      <Input
                        placeholder="Example Doe"
                        type="text"
                        name='name'
                        id='name'
                        value={values?.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mt-1"
                      />
                    </label>

                    <label className="block">
                      <Label>Mobile</Label>

                      <Input
                        type="tel"
                        placeholder="+966500000000"
                        name='phone'
                        value={values?.phone}          
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id='phone'
                        className="mt-1"
                      />
                    </label>

                    <label className="block">
                      <Label>Email address</Label>

                      <Input
                        type="email"
                        name='email'
                        id='email'
                        value={values?.email}   
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="example@example.com"
                        className="mt-1"
                      />
                    </label>
                    <label className="block">
                      <Label>Message</Label>

                      <Textarea 
                        className="mt-1" 
                        rows={6} 
                        value={values?.message}   
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='message'
                        id='message'
                      />
                    </label>
                    <div>
                      <ButtonPrimary type="submit">
                        {isSubmitting ? 'Loading ..' : 'Send Message'}
                      </ButtonPrimary>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PageContact;
